@tailwind base;
@tailwind components;
@tailwind utilities;

@layer container {
  .content-container {
    @apply mx-auto flex max-w-screen-lg justify-center;
  }
}

@layer utilities {
  .blue-scribbles-background {
    @apply bg-wl-blue-malibu-400 bg-blue-pattern bg-[length:200px] bg-repeat-round py-4;
  }
  .bulleted-list-coin {
    @apply bg-[url('/images/icon-coin.png')] bg-[length:25px] bg-left-top bg-no-repeat pl-10;
  }
  .cta-link {
    @apply inline-block rounded-md bg-wl-orange-500 px-4 py-2 text-xl font-bold text-white drop-shadow-[0px_2px_2px_rgba(0,0,0,0.5)] text-shadow-sm hover:bg-wl-orange-500 hover:text-white;
  }
}
